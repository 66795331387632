import { createWebHistory, createRouter } from "vue-router";

const routes = [
  {
    path: '/',
    meta: {
      hideForAuth: true
    },
    name: 'Login',
       component: () => import('../components/LoginComponent.vue')
  },
  {
    path: '/formularios',
    meta: {
      requiresAuth: true
    },
    name: 'Formularios',
       component: () => import('../views/formulariosList.vue')
  },
  {
    path: '/correos',
    meta: {
      requiresAuth: true
    },
    name: 'Correos',
       component: () => import('../views/correosList.vue')
  },
  {
    path: '/crear_formulario',
    meta: {
      requiresAuth: true
    },
    name: 'Crear formulario',
       component: () => import('../views/crearFormulario.vue')
  },
  {
    path: '/form/:id',
    meta: {
      hideForAuth: true
    },
    name: 'Llenar Formulario',
       component: () => import('../views/fillForm.vue')
  },
  {
    path: '/responses_piloto/:id/:form_info',
    meta: {
      hideForAuth: true
    },
    name: 'Respuestas de formulario del piloto',
       component: () => import('../views/showResponsesPiloto.vue')
  },
  {
    path: '/responses/:id',
    meta: {
      requiresAuth: true
    },
    name: 'Respuestas de formulario',
       component: () => import('../views/showResponses.vue')
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
      if(localStorage.getItem('userProfileAppTransportes') === null){
          next({ path: '/' });
      } else {
          next();
      }

  } else {
      next();
  }
});

export default router