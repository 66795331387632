export default class AadService {
    constructor() {
        const hostname = window.location.hostname;
        const regExpName = 'https://func-aad-service';
        const currentEnvironment = hostname.includes('dev') ? `${regExpName}-dev.azurewebsites.net` : hostname.includes('local') ? 'http://localhost:7071' : `${regExpName}-prod.azurewebsites.net`;
        this.apiBase = `${currentEnvironment}/api`;
    }

    async getAadUsersList() {
        let response = await this.GET(`/aad_users_list_get_http`);
        if (response) {
            let data = await response.json();
            return data;
        }
    }

    /* METHODS */

    async GET(endPoint) {
        let response = await fetch(`${this.apiBase}${endPoint}`);

        if (!response.ok) {
            throw new Error(
                `Call to ${this.apiBase}${endPoint} failed: ${response.statusText}`
            );
        }
        return response;
    }
}