import "bootstrap/dist/css/bootstrap.css"
import "bootstrap-icons/font/bootstrap-icons.css"

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vuex from 'vuex'
import formulariosBackend from "./backend/api"
import NotificationsService from "./backend/NotificationsService"
import AadService from "./backend/AadService"

const app = createApp(App)
app.use(router);
app.use(store);
app.use(Vuex);
app.config.globalProperties.$formulariosApi = new formulariosBackend();
app.config.globalProperties.$notificationsService = new NotificationsService();
app.config.globalProperties.$aadService = new AadService();
app.mount('#app');



import "bootstrap/dist/js/bootstrap.js"
