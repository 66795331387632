export default class NotificationsService {
    constructor() {
        const hostname = window.location.hostname;
        const regExpName = 'https://func-notifications-service';
        const currentEnvironment = hostname.includes('dev') ? `${regExpName}-dev.azurewebsites.net` : hostname.includes('local') ? 'http://localhost:7071' : `${regExpName}-prod.azurewebsites.net`;
        this.apiBase = `${currentEnvironment}/api`;
    }

    async sendPdfToEmail(body){
        let response = await this.POST("/send_email_attachment_post_http", body);
        return response;
    }

    /* METHODS */

    async POST(endPoint, body) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)
        };
        let response = await fetch(`${this.apiBase}${endPoint}`, requestOptions);
        if (!response.ok) {
            throw new Error(
                `Call to ${this.apiBase}${endPoint} failed: ${response.statusText}`
            );
        }
        return true;
    }
}