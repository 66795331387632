export default class formulariosBackend {
    constructor() {
        const hostname = window.location.hostname;
        const regExpName = 'https://func-forms-service';
        const currentEnvironment = hostname.includes('dev') ? `${regExpName}-dev.azurewebsites.net` : hostname.includes('local') ? 'http://localhost:7071' : `${regExpName}-prod.azurewebsites.net`;
        this.apiBase = `${currentEnvironment}/api`;
    }

    async getFormularios() {
        let response = await this.GET("/formularios_get_http");
        if (response) {
            let data = await response.json();
            return data;
        }
    }

    async getFormulariosById(id) {
        let response = await this.GET(`/formularios_by_id_get_http?id=${id}`);
        if (response) {
            let data = await response.json();
            return data;
        }
    }

    async createFormularios(formulario){
        let response = await this.POST("/formularios_create_post_http", formulario);
        return response;
    }

    async createCompletedFormularios(formulario){
        let response = await this.POST_RESULT("/completed_formularios_create_post_http", formulario);
        return response;
    }

    async deleteFormularios(id, usuario){
        let response = await this.DELETE(`/formularios_by_id_delete_http?id=${id}&usuario=${usuario}`);
        return response;
    }

    async getCompletedFormulariosById(id) {
        let response = await this.GET(`/completed_formularios_by_id_get_http?id=${id}`);
        if (response) {
            let data = await response.json();
            return data;
        }
    }

    async searchTextInForms(id, text, idate, edate) {
        let response = await this.GET(`/formularios_search_get_http?id=${id}&text=${text}&idate=${idate}&edate=${edate}`);
        if (response) {
            let data = await response.json();
            return data;
        }
    }


    async exportDataExcel(id, idate, edate) {
        let response = await this.GET(`/data_to_excel_create_post_http?id=${id}&fecha_inicio=${idate}&fecha_fin=${edate}`);
        if (response) {
            let data = await response.json();
            return data;
        }
    }


    async getCompletedFormularioById(id) {
        let response = await this.GET(`/completed_formulario_by_id_get_http?id=${id}`);
        if (response) {
            let data = await response.json();
            return data;
        }
    }

    async uploadImageToBlobStorage(blob){
        let response = await this.POST_RESULT("/blob_upload_post_http", blob);
        return response;
    }


    /* Correos */
    async getCorreosNotificacion() {
        let response = await this.GET("/correos_notificacion_get_http");
        if (response) {
            let data = await response.json();
            return data;
        }
    }

    async createCorreosNotificacion(formulario){
        let response = await this.POST("/correos_notificacion_create_post_http", formulario);
        return response;
    }

    async deleteCorreosNotificacion(id){
        let response = await this.DELETE(`/correos_notificacion_by_id_delete_http?id=${id}`);
        return response;
    }


    /* METHODS */

    async POST(endPoint, body) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)
        };
        let response = await fetch(`${this.apiBase}${endPoint}`, requestOptions);
        if (!response.ok) {
            throw new Error(
                `Call to ${this.apiBase}${endPoint} failed: ${response.statusText}`
            );
        }
        return true;
    }

    async POST_RESULT(endPoint, body) {
        const requestOptions = {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)
        };
        let response = await fetch(`${this.apiBase}${endPoint}`, requestOptions);
        if (!response.ok) {
            throw new Error(
                `Call to ${this.apiBase}${endPoint} failed: ${response.statusText}`
            );
        }else{
            let data = await response.json();
            return data
        }
    }

    async PUT(endPoint, body) {
        const requestOptions = {
            method: "PUT",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body)

        };
        let response = await fetch(`${this.apiBase}${endPoint}`, requestOptions);
        if (!response.ok) {
            throw new Error(
                `Call to ${this.apiBase}${endPoint} failed: ${response.statusText}`
            );
        }
        return true;
    }

    async GET(endPoint) {
        let response = await fetch(`${this.apiBase}${endPoint}`);

        if (!response.ok) {
            throw new Error(
                `Call to ${this.apiBase}${endPoint} failed: ${response.statusText}`
            );
        }
        return response;
    }

    async DELETE(endPoint) {
        const requestOptions = {
            method: "DELETE"
        };
        let response = await fetch(`${this.apiBase}${endPoint}`, requestOptions);

        if (!response.ok) {
            throw new Error(
                `Call to ${this.apiBase}${endPoint} failed: ${response.statusText}`
            );
        }
        return true;
    }
}