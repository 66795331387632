<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark" style="background-color: #000000 !important;">
    <router-link class="nav-link text-light" to="/"><img :src="require('../assets/logoSaul.png')" height="40" class="d-inline-block align-top"></router-link>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse font-weight-bold" id="navbarNavDropdown">
    <ul class="navbar-nav ml-auto" v-if="userAccount">
      <li class="nav-item active">
        <router-link class="nav-link text-light" to="/"><i class="bi bi-house"></i> Inicio</router-link>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle text-light" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="bi bi-ui-checks"></i>  Formularios
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <router-link  class="dropdown-item" to="/formularios">Formularios</router-link>
          <router-link  class="dropdown-item" to="/crear_formulario">Crear Formulario</router-link>
        </div>
      </li>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle text-light" href="#" id="navbarDropdownConf" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <i class="bi bi-gear-fill"></i> Configuraciones
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdownConf">
          <router-link  class="dropdown-item" to="/correos">Correos</router-link>
        </div>
      </li>
    </ul>
  </div>
</nav>
</template>

<script>
import { mapState } from "vuex";
import auth from "../services/auth";

export default {
  name: 'NavBar',
  components: {},

  data() {
    return {
      group: false,
      sidebar: false,
    };
  },

  mounted() {
    this.getUserFromLocalSession()
  },

  computed: {
    ...mapState(["menuItems", "userAccount"]),
  },
  methods: {
    invokeMethod(methodName) {
      this[methodName]();
    },
    console() {
      console.log(' ')
    },
    async SignOut() {
      try {
        let logout = await auth.SignOut();
        if (logout) {
          this.$store.commit("setUserAccount", {
            account: undefined,
            userGraphInfo: undefined
          });
        }
      } catch (err) {
        this.error = err.toString();
      }
    },

    getUserFromLocalSession() {
      if (!this.userAccount) {
        this.$store.commit("setUserAccount", {
              account: localStorage.getItem('userProfileMantenimientosComerciales'),
        });
      }
    }
  }
}
</script>
<style>

 #navbarNavDropdown .navbar-nav li.active > a {
	background-color: #ff0000;
	color: #fff;
}
</style>
