import { createStore } from 'vuex'
import Swal from 'sweetalert2'

export default createStore({

  state: {
    userAccount: undefined,
    userGraphInfo: undefined,
    appTitle: 'Saúl E. Méndez'
  },

  getters: {
  },

  mutations: {
    setUserAccount: (state, payload) => {
      const { account } = payload
      state.userAccount = JSON.parse(account)
    },
    setUserGraphInfo: (state, payload) => {
      const { graphInfo } = payload
      state.userGraphInfo = graphInfo
    },
    mostrarLoading: (state, payload) => {
      if(payload.lanzar){
        Swal.fire({
            title: payload.texto,
            allowEscapeKey: false,
            allowOutsideClick: false,
            didOpen: () => {
              Swal.showLoading()
            }
          });
      }else{
        Swal.close()
      }
    },
    mostrarMsgTopPosition(state, payload) {
        Swal.fire({
          position: 'top-end',
          icon: payload.icon,
          title: payload.texto,
          showConfirmButton: false,
          timer: 1500
        })
    }
  },

  actions: {
    async fillUserFromLocalStorage({ commit }){
      if(localStorage.getItem('userProfileAppTransportes') !== null){
        commit("setUserAccount", {
          account: localStorage.getItem('userProfileAppTransportes'),
        });
      }
    }
  },

  modules: {
  }
})
